@import "bootstrap/dist/css/bootstrap.min.css";
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
html {
  --main-color: #2d1f52;
  --contrast-color: #f2f2f2;
  --accent-color: #b3e0f2; /*#731425;*/
  --menu-back: #26528c;
  scroll-behavior: smooth;
  --second-accent-color: #8c2652;
}
* {
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
  font-family: "Noto Sans", sans-serif;
  font-size: 19px;
}
.container {
  margin: 0 auto;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  text-transform: uppercase;
}
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: var(--contrast-color);
}
h2 {
  font-size: 1.8rem;
}


.App {
  position: relative;
}
.toTopButton {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-color);
  color: white;
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
  z-index: 100;
}
.toTopButton.hidden {
  display: none;
}
.common__section .container {
  width: 1120px;
  margin: 0 auto;
  padding: 60px 0;
}

.btn-primary {
  padding: 10px 20px;
  max-width: 100%;
  border-radius: 10px;
  min-height: 60px;
  background: var(--second-accent-color);
  border: none;
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn-primary:hover {
  background: var(--accent-color);
  color: var(--main-color);
  font-weight: bold;
  cursor: pointer;
}
@media (min-width: 1201px) and (max-width: 1365px) {
  .common__section .container {
    width: 1100px;
  }
}
@media (max-width: 1200px) {
  html,
  body {
    font-family: "Jost", sans-serif;
  }
  .container,
  .common__section .container {
    width: 100vw;
    max-width: 100vw;
    padding: 40px 10px;
  }
  .toTopButton {
    bottom: 10px;
    right: 10px;
  }
}
@media (max-width: 450px) {
  html,
  body {
    font-size: 14px;
  }
  p,
  span,
  li,
  a {
    font-size: 16px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
  .btn-primary {
    min-height: 46px;
  }
}
@media (max-height: 450px) {
  html,
  body {
    font-size: 14px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
  .btn-primary {
    min-height: 46px;
  }
}

@media (max-width: 340px) {
  html,
  body {
    font-size: 12px;
  }
}

.modalOverlay {
  background-color: rgba(51, 51, 51, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modalContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 7px;
  box-shadow: 0 0 32px rgba(51, 51, 51, 0.4);
  padding: 40px;
  width: 450px;
  font-size: 26px;
  z-index: 999;
  position: relative;
}
.modalContainer .contactForm > div {
  margin-bottom: 0;
}
.modalContainer .contactForm > div:last-child {
  margin-top: 40px;
}
.modalContainer .closeForm {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: auto;
  display: block;
}
.modalContainer .closeForm:hover {
  cursor: pointer;
}
.modalContainer .contactForm {
  padding: 10px 0;
  row-gap: 10px;
}
.modalContainer .contactFormWrap .alert {
  font-size: 1rem;
  text-align: center;
  place-content: center;
  width: 100%;
  margin: 0 100px;
}
.modalContainer .form-group {
  position: relative;
}

.modalContainer .imgCheckPhone {
  width: 20px;
  position: absolute;
  bottom: 12px;
  right: 20px;
}
.modalContainer .PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.modalContainer .PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.modalContainer .contactForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.modalContainer .contactForm .form-group {
  width: 48%;
  height: 90px;
}

.modalContainer .contactForm .form-group.form-user {
  padding-right: 20px;
}

.modalContainer .contactForm .cF-button {
  width: 100%;
}
.modalContainer .formTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalContainer .formTitle h2 {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--main-color);
}
.modalContainer .formTitle p {
  text-align: center;
  color: var(--main-color);
  font-size: 1.1rem;
}
.modalContainer .cF-button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.modalContainer .form-label {
  font-size: 1.2rem;
}
.modalContainer .contactForm .btn-primary {
  width: auto;
  max-width: 100%;
  /* background: #535f61;
  color: white; */
}
/* .contactForm .btn-primary:hover {
  background: var(--main-color);
} */

@media (max-width: 640px) {
  .modalContainer .formTitle h2 {
    font-size: 1.1rem;
  }
  .modalContainer .contactForm label {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .modalContainer {
    padding: 40px 10px 20px;
  }
  .modalContainer .form-group {
    margin-bottom: 0;
  }

  .modalContainer .contactForm .form-group,
  .modalContainer .contactForm .form-group.form-user {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    height: auto;
  }

  .modalContainer .contactForm > div:last-child {
    margin-top: 5px;
  }
  .modalContainer .contactForm {
    padding: 0;
  }
  .modalContainer .contactForm .btn-primary {
    padding: 5px 20px;
    font-size: 1rem;
  }
}

@media (max-height: 450px) {
  .modalContainer .closeForm {
    top: 27px;
  }
}
