.second h2 {
  color: var(--main-color);
}
.second .innerSection {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 1fr;
  column-gap: 60px;
}

.second .rightBlock {
  padding: 60px 0px 40px 60px;
}
.second .container.move {
  margin-left: 35vw;
}
.second .container.unmove {
  margin-left: 0 auto;
}
.second .contactForm {
  max-width: 600px;
}
.imgCheckPhone,
.imgCheckEmail {
  width: 18px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.imgCheckPhone {
  bottom: 16px;
}
.PhoneInputInput {
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
}
.PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.form-phone,
.form-email {
  position: relative;
}
.contactForm label,
.contactForm input {
  font-size: 14px;
}
.contactForm label {
  font-weight: bold;
  color: var(--main-color);
}
.contactForm > div {
  margin-bottom: 15px;
}
.contactForm > div:last-child {
  margin-bottom: 0;
}
.contactForm > div:last-child {
  margin-top: 30px;
}
/* .contactForm .btn-primary {
  background: #5a30f2;
  border: none;
}
.contactForm .btn-primary:hover {
  background: var(--accent-color);
} */

@media (max-width: 1200px) {
  .second .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 60px;
  }
  /* .second .blocks {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    row-gap: 10px;
  } */
  .second .blocks.leftBlock {
    grid-template-rows: 25% 18% auto;
  }
  .second .image {
    width: 15%;
  }
  .second .image-2,
  .second .image-4 {
    top: 25%;
  }
  .second .image-4 {
    left: 56%;
  }

  .second .imgBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .second .textImage {
    width: 70%;
  }
  /* .second .image {
    top: 70px;
    left: 39%;
    width: 22%;
  } */
  .contactForm {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
  }
  .contactForm > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
  }
  .contactForm > div:last-child {
    margin-top: 0px;
  }
  .cF-button button {
    margin-bottom: 6px;
  }
}

@media (max-width: 800px) {
  .second .blocks.leftBlock {
    display: none;
  }
  .second .innerSection {
    grid-template-columns: 100%;
  }
  .second .rightBlock {
    padding: 20px 10px;
    margin: 0;
  }
  .second .rightBlock {
    padding: 20px 10px;
    margin: 0;
  }
  .contactForm > div {
    width: 100%;
  }
  .second .btn-primary {
    min-width: auto;
    max-width: 100%;
    padding: 10px 20px;
  }
  .second .contactForm {
    margin: 0 auto;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .second .cF-button {
    display: flex;
    align-items: center;
  }
  .second .btn-primary {
    min-width: auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 5px 10px;
  }
}
