@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap"); */
html {
  --main-color: #2d1f52;
  --contrast-color: #f2f2f2;
  --accent-color: #b3e0f2; /*#731425;*/
  --menu-back: #26528c;
  scroll-behavior: smooth;
  --second-accent-color: #8c2652;
}
* {
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
  font-family: "Noto Sans", sans-serif;
  font-size: 19px;
}
.container {
  margin: 0 auto;
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  text-transform: uppercase;
}
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: #f2f2f2;
  color: var(--contrast-color);
}
h2 {
  font-size: 1.8rem;
}


.App {
  position: relative;
}
.toTopButton {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b3e0f2;
  background: var(--accent-color);
  color: white;
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
  z-index: 100;
}
.toTopButton.hidden {
  display: none;
}
.common__section .container {
  width: 1120px;
  margin: 0 auto;
  padding: 60px 0;
}

.btn-primary {
  padding: 10px 20px;
  max-width: 100%;
  border-radius: 10px;
  min-height: 60px;
  background: #8c2652;
  background: var(--second-accent-color);
  border: none;
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn-primary:hover {
  background: #b3e0f2;
  background: var(--accent-color);
  color: #2d1f52;
  color: var(--main-color);
  font-weight: bold;
  cursor: pointer;
}
@media (min-width: 1201px) and (max-width: 1365px) {
  .common__section .container {
    width: 1100px;
  }
}
@media (max-width: 1200px) {
  html,
  body {
    font-family: "Jost", sans-serif;
  }
  .container,
  .common__section .container {
    width: 100vw;
    max-width: 100vw;
    padding: 40px 10px;
  }
  .toTopButton {
    bottom: 10px;
    right: 10px;
  }
}
@media (max-width: 450px) {
  html,
  body {
    font-size: 14px;
  }
  p,
  span,
  li,
  a {
    font-size: 16px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
  .btn-primary {
    min-height: 46px;
  }
}
@media (max-height: 450px) {
  html,
  body {
    font-size: 14px;
  }
  .container,
  .common__section .container {
    padding: 10px;
  }
  .btn-primary {
    min-height: 46px;
  }
}

@media (max-width: 340px) {
  html,
  body {
    font-size: 12px;
  }
}

.modalOverlay {
  background-color: rgba(51, 51, 51, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modalContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 7px;
  box-shadow: 0 0 32px rgba(51, 51, 51, 0.4);
  padding: 40px;
  width: 450px;
  font-size: 26px;
  z-index: 999;
  position: relative;
}
.modalContainer .contactForm > div {
  margin-bottom: 0;
}
.modalContainer .contactForm > div:last-child {
  margin-top: 40px;
}
.modalContainer .closeForm {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: auto;
  display: block;
}
.modalContainer .closeForm:hover {
  cursor: pointer;
}
.modalContainer .contactForm {
  padding: 10px 0;
  grid-row-gap: 10px;
  row-gap: 10px;
}
.modalContainer .contactFormWrap .alert {
  font-size: 1rem;
  text-align: center;
  place-content: center;
  width: 100%;
  margin: 0 100px;
}
.modalContainer .form-group {
  position: relative;
}

.modalContainer .imgCheckPhone {
  width: 20px;
  position: absolute;
  bottom: 12px;
  right: 20px;
}
.modalContainer .PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid #8c2652;
  border: 1px solid var(--second-accent-color);
}
.modalContainer .PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid #8c2652;
  border: 1px solid var(--second-accent-color);
}
.modalContainer .contactForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.modalContainer .contactForm .form-group {
  width: 48%;
  height: 90px;
}

.modalContainer .contactForm .form-group.form-user {
  padding-right: 20px;
}

.modalContainer .contactForm .cF-button {
  width: 100%;
}
.modalContainer .formTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalContainer .formTitle h2 {
  text-transform: uppercase;
  font-weight: bold;
  color: #2d1f52;
  color: var(--main-color);
}
.modalContainer .formTitle p {
  text-align: center;
  color: #2d1f52;
  color: var(--main-color);
  font-size: 1.1rem;
}
.modalContainer .cF-button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.modalContainer .form-label {
  font-size: 1.2rem;
}
.modalContainer .contactForm .btn-primary {
  width: auto;
  max-width: 100%;
  /* background: #535f61;
  color: white; */
}
/* .contactForm .btn-primary:hover {
  background: var(--main-color);
} */

@media (max-width: 640px) {
  .modalContainer .formTitle h2 {
    font-size: 1.1rem;
  }
  .modalContainer .contactForm label {
    font-size: 0.9rem;
    font-weight: bold;
  }
  .modalContainer {
    padding: 40px 10px 20px;
  }
  .modalContainer .form-group {
    margin-bottom: 0;
  }

  .modalContainer .contactForm .form-group,
  .modalContainer .contactForm .form-group.form-user {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    height: auto;
  }

  .modalContainer .contactForm > div:last-child {
    margin-top: 5px;
  }
  .modalContainer .contactForm {
    padding: 0;
  }
  .modalContainer .contactForm .btn-primary {
    padding: 5px 20px;
    font-size: 1rem;
  }
}

@media (max-height: 450px) {
  .modalContainer .closeForm {
    top: 27px;
  }
}

.banner {
  background: url(/static/media/banner.9a71da4c.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
}
.bannerInner {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: rgba(45, 31, 82, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner .gridBanner {
  z-index: 21;
  color: var(--contrast-color);
  display: flex;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
}
.gridBanner .leftBlock {
  width: 60%;
}
.gridBanner .rightBlock {
  width: 40%;
  background: rgba(45, 31, 82, 1);
  border-radius: 20px;
  box-shadow: 1px 0px 10px 2px rgba(255, 255, 255, 0.4);
  padding: 20px;
}
.gridBanner .rightBlock h3 {
  text-transform: none;
  color: var(--accent-color);
}
.gridBanner .rightBlock .cF-button {
  display: flex;
  justify-content: center;
}
.gridBanner .contactForm label {
  color: var(--accent-color);
}
.banner .container .bannerText {
  display: flex;
  flex-direction: column;
}
.banner .container .bannerText > * {
  text-align: left;
}
.banner .container.move {
  margin-left: 30vw;
}
/*
.banner .container.unmove {
  margin-left: 0;
} */
h1 {
  font-size: 2.2rem;
  color: var(--accent-color);
  font-weight: bold;
  margin-bottom: 20px;
}
.bannerText {
  margin-top: 60px;
}
.banner h2 {
  margin: 20px 0;
  color: var(--contrast-color);
}
.banner-descr {
  line-height: 1.5;
  font-size: 1.5rem;
}
.bannerLogo img {
  height: 120px;
}
/* .banner .btn-primary {
  background-color: var(--accent-color);
  color: var(--main-color);
  font-weight: bold;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
  padding: 10px 20px;
} */
.banner-btn {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 40px;
}
@media (max-width: 1200px) {
  .bannerTop {
    height: auto;
  }
  .banner {
    position: relative;
    height: auto;
  }
  .banner .container {
    width: auto;
  }
  .bannerLogo img {
    height: 100px;
  }
}
/* @media (max-width: 800px) {
  .gridBanner .block {
    width: 50%;
  }
  .bannerLogo img {
    height: 80px;
  }
} */
@media (max-width: 800px) {
  .banner {
    position: relative;
    height: auto;
  }
  .banner h1 {
    margin-bottom: 10px;
  }
  .banner h2 {
    margin: 10px 0;
  }
  .banner .container {
    padding: 20px;
    width: auto;
    margin: 10px;
  }
  .banner .gridBanner {
    flex-wrap: wrap;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .banner .gridBanner .block {
    width: 100%;
  }
  .banner .btn-primary {
    min-width: 0;
  }
  .bannerLogo img {
    height: 80px;
  }
  .bannerText {
    margin-top: 20px;
  }
  .banner-btn {
    margin-top: 20px;
  }
  .banner-btn .btn-primary {
    min-width: 70%;
    padding: 10px 20px;
  }
}
@media (max-height: 450px) and (min-width: 700px) {
  .banner .gridBanner .leftBlock {
    width: 60%;
  }
  .banner .gridBanner .rightBlock {
    width: 40%;
  }
  .banner .container {
    margin: 0px;
    padding: 10px;
  }
  /*.bannerText {
    margin-top: 20px;
  } */
  h1 {
    margin-bottom: 10px;
  }
  .bannerInner .banner-btn {
    margin-top: 10px;
  }
  .bannerLogo img {
    height: 60px;
  }
}
@media (max-height: 450px) {
  .bannerText {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 10px;
    font-size: 1.9rem;
  }
  .bannerInner .banner-btn {
    margin-top: 10px;
  }
  .banner-descr {
    color: white;
  }
}

@media (max-width: 450px) {
  .banner h1 {
    font-size: 1.8rem;
  }

  .banner h2 {
    font-size: 1.5rem;
  }

  .banner h3 {
    font-size: 1.2rem;
  }
  .bannerLogo img {
    height: 60px;
  }
  .banner .container {
    padding: 0px;
  }
}

.second h2 {
  color: var(--main-color);
}
.second .innerSection {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 1fr;
  grid-column-gap: 60px;
  -webkit-column-gap: 60px;
          column-gap: 60px;
}

.second .rightBlock {
  padding: 60px 0px 40px 60px;
}
.second .container.move {
  margin-left: 35vw;
}
.second .container.unmove {
  margin-left: 0 auto;
}
.second .contactForm {
  max-width: 600px;
}
.imgCheckPhone,
.imgCheckEmail {
  width: 18px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
.imgCheckPhone {
  bottom: 16px;
}
.PhoneInputInput {
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
}
.PhoneInput--focus input {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.PhoneInput input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  border: 1px solid var(--second-accent-color);
}
.form-phone,
.form-email {
  position: relative;
}
.contactForm label,
.contactForm input {
  font-size: 14px;
}
.contactForm label {
  font-weight: bold;
  color: var(--main-color);
}
.contactForm > div {
  margin-bottom: 15px;
}
.contactForm > div:last-child {
  margin-bottom: 0;
}
.contactForm > div:last-child {
  margin-top: 30px;
}
/* .contactForm .btn-primary {
  background: #5a30f2;
  border: none;
}
.contactForm .btn-primary:hover {
  background: var(--accent-color);
} */

@media (max-width: 1200px) {
  .second .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 60px;
    row-gap: 60px;
  }
  /* .second .blocks {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    row-gap: 10px;
  } */
  .second .blocks.leftBlock {
    grid-template-rows: 25% 18% auto;
  }
  .second .image {
    width: 15%;
  }
  .second .image-2,
  .second .image-4 {
    top: 25%;
  }
  .second .image-4 {
    left: 56%;
  }

  .second .imgBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .second .textImage {
    width: 70%;
  }
  /* .second .image {
    top: 70px;
    left: 39%;
    width: 22%;
  } */
  .contactForm {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .contactForm > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0;
  }
  .contactForm > div:last-child {
    margin-top: 0px;
  }
  .cF-button button {
    margin-bottom: 6px;
  }
}

@media (max-width: 800px) {
  .second .blocks.leftBlock {
    display: none;
  }
  .second .innerSection {
    grid-template-columns: 100%;
  }
  .second .rightBlock {
    padding: 20px 10px;
    margin: 0;
  }
  .second .rightBlock {
    padding: 20px 10px;
    margin: 0;
  }
  .contactForm > div {
    width: 100%;
  }
  .second .btn-primary {
    min-width: auto;
    max-width: 100%;
    padding: 10px 20px;
  }
  .second .contactForm {
    margin: 0 auto;
  }
}
@media (max-height: 450px) and (min-width: 800px) {
  .second .cF-button {
    display: flex;
    align-items: center;
  }
  .second .btn-primary {
    min-width: auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 5px 10px;
  }
}

.howToStart {
  position: relative;
}
.howToStart .container.move {
  margin-left: 35vw;
}
.howToStart .container.unmove {
  margin-left: 0 auto;
}

.howToStart h2 {
  color: var(--main-color);
}
.howToStart .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 40px;
  row-gap: 40px;
  justify-content: space-around;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.howToStart .list li {
  background: var(--contrast-color);
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #f2c46d;
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px -2px 5px 0 rgba(0, 0, 0, 0.1), 2px 2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s all linear;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.howToStart .list li .firstL {
  border-radius: 5px;
  padding: 5px;
  border: 2px dotted #9d84bf;
  height: 100%;
  width: 100%;
}

.howToStart .list li .secondL {
  border-radius: 5px;
  padding: 10px;
  border: 2px solid #f2c46d;
  height: 100%;
  width: 100%;
}
.howToStart .btn-primary {
  position: relative;
}
.list li .number {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
  top: -35px;
  left: calc(50% - 15px);
}

@media (max-width: 700px) {
  .howToStart .list {
    flex-wrap: wrap;
    grid-row-gap: 20px;
    row-gap: 20px;
    justify-content: space-around;
  }
  .howToStart .list li {
    width: 48%;
  }
}
@media (max-width: 450px) {
  .howToStart .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .howToStart .container {
    padding-bottom: 50px;
  }
  .howToStart .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 0;
  }
}
@media (max-height: 450px) and (min-width: 700px) {
  .howToStart .container {
    padding: 20px 10px;
  }
  .howToStart .list {
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

.tesimonials__external {
  position: relative;
  background: url(/static/media/fourthBg.39737aa1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.tesimonials__external h3,
.tesimonials__external .descr {
  color: #fff;
}
.carousel-control-prev {
  left: -60px;
}

.carousel-control-next {
  right: -60px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: var(--second-accent-color);
  padding: 15px;
  display: block;
  border-radius: 50%;
  background-size: 24px;
  background-position-x: 8px;
}
.tesimonials__external h5 {
  color: var(--contrast-color);
}
.testimonial-content p {
  border: 2px solid var(--main-color);
  border-radius: 10px;
  position: relative;
  left: -20px;
  bottom: -10px;
  background: #fff;
  z-index: 20;
}

.testimonial-content p:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var(--main-color);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -11px;
  bottom: 13px;
  background: #fff;
  border-right: none;
  border-top: none;
}
#testimonials .carousel.slide {
  width: 100%;
  padding: 35px;
}

#testimonials .carousel.slide p {
  font-style: italic;
}

#testimonials .user-avatar img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 20;
}
#testimonials .user-avatar h5 {
  z-index: 20;
}

.light2 {
  position: absolute;
  width: 0px;
  /* height: 100px; */
  opacity: 0.95;
  background-color: var(--contrast-color);
  box-shadow: #fff 0px 0px 20px 2px;
  opacity: 0;
  top: 0vh;
  /* top: 50vh;*/
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: 1;
}

@media screen and (max-width: 1200px) {
  .slide-inner .user-avatar {
    display: flex;
    flex-flow: column;
    place-content: flex-end;
    justify-content: center;
    align-items: center;
  }
  .testimonial-content p:after {
    bottom: 50%;
  }
}
@media screen and (max-width: 992px) {
  .testimonial-content p:after {
    display: none;
  }
  #testimonials .carousel-control-prev {
    left: -10px;
  }

  #testimonials .carousel-control-next {
    right: -10px;
  }

  #testimonials .user-avatar img {
    width: 100px;
  }
  .testimonial-content p {
    left: 0;
  }
  #testimonials .carousel-control-prev,
  #testimonials .carousel-control-next {
    top: -200px;
  }
}
@media (max-width: 767px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}
@media screen and (max-height: 450px) {
  #testimonials .user-avatar img {
    width: 150px;
  }
  .testimonial-content p {
    left: 0;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}

@media screen and (max-width: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}
@media screen and (max-height: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}

.whywe {
  background: url(/static/media/thirdBg.3fff45a2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 0px 10px 2px rgba(45, 31, 82, 0.4) inset;
}
.whywe .innerSection {
  display: flex;
  align-items: center;
}

.whywe h2,
.whywe h4 {
  color: var(--main-color);
}

.benefits {
  background: url(/static/media/fourthBg.39737aa1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: var(--contrast-color);
}
.benefits .innerSection {
  display: flex;
  grid-column-gap: 60px;
  -webkit-column-gap: 60px;
          column-gap: 60px;
}

.benefits .imageBlock {
  width: 40%;
  background: rgba(45, 31, 82, 0.8);
  border-radius: 20px;
  box-shadow: 1px 0px 10px 2px rgba(255, 255, 255, 0.4);
  padding: 20px;
}
.benefits .imageBlock .cF-button {
  display: flex;
  justify-content: center;
}
.benefits .imageBlock .cF-button .btn-primary {
  min-height: 0;
  padding: 10px 20px;
  font-size: 1rem;
}
.benefits .imageBlock .form-label {
  color: var(--accent-color);
}
.benefits .textBlock {
  width: 60%;
  background: rgba(45, 31, 82, 0.8);
  border-radius: 20px;
  box-shadow: 1px 0px 10px 2px rgba(255, 255, 255, 0.4);
  padding: 20px;
}
.benefits .container.move {
  margin-left: 35vw;
}
.benefits .container.unmove {
  margin-left: 0 auto;
}
.benefits h2,
.benefits h4 {
  color: var(--contrast-color);
}
.benefits h4 {
  text-transform: none;
}
.benefits .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}

.benefits .list li {
  color: var(--contrast-color);
  display: grid;
  grid-template-columns: 50px auto;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.benefits .list li .numbers {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid var(--contrast-color);
}

@media (max-width: 800px) {
  .benefits .innerSection {
    flex-wrap: wrap;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
  .benefits .imageBlock {
    width: 100%;
    order: 2;
  }
  .benefits .imageBlock .btn-primary {
    min-width: 0;
  }
  .benefits .textBlock {
    width: 100%;
    order: 1;
  }

  .benefits .list li {
    grid-template-columns: 30px auto;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    margin-bottom: 10px;
  }
  .benefits .list li .numbers {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 450px) {
  .benefits .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .benefits .list {
    margin: 20px 0;
  }
}
@media (max-height: 450px) and (min-width: 700px) {
  .benefits .innerSection {
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    flex-wrap: nowrap;
  }
  .benefits .textBlock {
    width: 60%;
    order: 2;
  }
  .benefits .imageBlock {
    width: 40%;
    order: 1;
  }
  .benefits .textBlock {
    padding: 20px 10px;
  }
}

.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 80px;
  left: 50px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0bb10b00;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;  
}

.whatsapp-icon:hover, .tg-icon:hover { 
    background-color: #20ad2033;
    -webkit-animation-name: unset;
            animation-name: unset;
    opacity: 0.8;
}

.tg-icon svg,
.whatsapp-icon svg {
  width: 50px;
  height: 50px;
}

.tg-icon svg {
  width: 45px;
  height: 45px;
  fill: #fff;
  border-radius: 5px;
}

.tg-icon {
    bottom: 160px;
    background: #03a9f4;
}

.tg-icon:hover {
    background: #03a9f433;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg)scale(1);
            transform: rotate(0deg)scale(1);
  }
  50% {
    -webkit-transform: rotate(-25deg)scale(1.2);
            transform: rotate(-25deg)scale(1.2);
  }
  100% {
    -webkit-transform: rotate(0deg)scale(1);
            transform: rotate(0deg)scale(1);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg)scale(1);
            transform: rotate(0deg)scale(1);
  }
  50% {
    -webkit-transform: rotate(-25deg)scale(1.2);
            transform: rotate(-25deg)scale(1.2);
  }
  100% {
    -webkit-transform: rotate(0deg)scale(1);
            transform: rotate(0deg)scale(1);
  }
}
