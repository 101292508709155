.benefits {
  background: url("../images/fourthBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: var(--contrast-color);
}
.benefits .innerSection {
  display: flex;
  column-gap: 60px;
}

.benefits .imageBlock {
  width: 40%;
  background: rgba(45, 31, 82, 0.8);
  border-radius: 20px;
  box-shadow: 1px 0px 10px 2px rgba(255, 255, 255, 0.4);
  padding: 20px;
}
.benefits .imageBlock .cF-button {
  display: flex;
  justify-content: center;
}
.benefits .imageBlock .cF-button .btn-primary {
  min-height: 0;
  padding: 10px 20px;
  font-size: 1rem;
}
.benefits .imageBlock .form-label {
  color: var(--accent-color);
}
.benefits .textBlock {
  width: 60%;
  background: rgba(45, 31, 82, 0.8);
  border-radius: 20px;
  box-shadow: 1px 0px 10px 2px rgba(255, 255, 255, 0.4);
  padding: 20px;
}
.benefits .container.move {
  margin-left: 35vw;
}
.benefits .container.unmove {
  margin-left: 0 auto;
}
.benefits h2,
.benefits h4 {
  color: var(--contrast-color);
}
.benefits h4 {
  text-transform: none;
}
.benefits .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
}

.benefits .list li {
  color: var(--contrast-color);
  display: grid;
  grid-template-columns: 50px auto;
  column-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.benefits .list li .numbers {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid var(--contrast-color);
}

@media (max-width: 800px) {
  .benefits .innerSection {
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 20px;
  }
  .benefits .imageBlock {
    width: 100%;
    order: 2;
  }
  .benefits .imageBlock .btn-primary {
    min-width: 0;
  }
  .benefits .textBlock {
    width: 100%;
    order: 1;
  }

  .benefits .list li {
    grid-template-columns: 30px auto;
    column-gap: 10px;
    margin-bottom: 10px;
  }
  .benefits .list li .numbers {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 450px) {
  .benefits .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .benefits .list {
    margin: 20px 0;
  }
}
@media (max-height: 450px) and (min-width: 700px) {
  .benefits .innerSection {
    column-gap: 20px;
    flex-wrap: nowrap;
  }
  .benefits .textBlock {
    width: 60%;
    order: 2;
  }
  .benefits .imageBlock {
    width: 40%;
    order: 1;
  }
  .benefits .textBlock {
    padding: 20px 10px;
  }
}
