.whywe {
  background: url("../images/thirdBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 0px 10px 2px rgba(45, 31, 82, 0.4) inset;
}
.whywe .innerSection {
  display: flex;
  align-items: center;
}

.whywe h2,
.whywe h4 {
  color: var(--main-color);
}
