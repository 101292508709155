.banner {
  background: url("../images/banner/banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
}
.bannerInner {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: rgba(45, 31, 82, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner .gridBanner {
  z-index: 21;
  color: var(--contrast-color);
  display: flex;
  column-gap: 40px;
}
.gridBanner .leftBlock {
  width: 60%;
}
.gridBanner .rightBlock {
  width: 40%;
  background: rgba(45, 31, 82, 1);
  border-radius: 20px;
  box-shadow: 1px 0px 10px 2px rgba(255, 255, 255, 0.4);
  padding: 20px;
}
.gridBanner .rightBlock h3 {
  text-transform: none;
  color: var(--accent-color);
}
.gridBanner .rightBlock .cF-button {
  display: flex;
  justify-content: center;
}
.gridBanner .contactForm label {
  color: var(--accent-color);
}
.banner .container .bannerText {
  display: flex;
  flex-direction: column;
}
.banner .container .bannerText > * {
  text-align: left;
}
.banner .container.move {
  margin-left: 30vw;
}
/*
.banner .container.unmove {
  margin-left: 0;
} */
h1 {
  font-size: 2.2rem;
  color: var(--accent-color);
  font-weight: bold;
  margin-bottom: 20px;
}
.bannerText {
  margin-top: 60px;
}
.banner h2 {
  margin: 20px 0;
  color: var(--contrast-color);
}
.banner-descr {
  line-height: 1.5;
  font-size: 1.5rem;
}
.bannerLogo img {
  height: 120px;
}
/* .banner .btn-primary {
  background-color: var(--accent-color);
  color: var(--main-color);
  font-weight: bold;
  margin: 0 auto;
  width: auto;
  max-width: 100%;
  padding: 10px 20px;
} */
.banner-btn {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 40px;
}
@media (max-width: 1200px) {
  .bannerTop {
    height: auto;
  }
  .banner {
    position: relative;
    height: auto;
  }
  .banner .container {
    width: auto;
  }
  .bannerLogo img {
    height: 100px;
  }
}
/* @media (max-width: 800px) {
  .gridBanner .block {
    width: 50%;
  }
  .bannerLogo img {
    height: 80px;
  }
} */
@media (max-width: 800px) {
  .banner {
    position: relative;
    height: auto;
  }
  .banner h1 {
    margin-bottom: 10px;
  }
  .banner h2 {
    margin: 10px 0;
  }
  .banner .container {
    padding: 20px;
    width: auto;
    margin: 10px;
  }
  .banner .gridBanner {
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 20px;
  }
  .banner .gridBanner .block {
    width: 100%;
  }
  .banner .btn-primary {
    min-width: 0;
  }
  .bannerLogo img {
    height: 80px;
  }
  .bannerText {
    margin-top: 20px;
  }
  .banner-btn {
    margin-top: 20px;
  }
  .banner-btn .btn-primary {
    min-width: 70%;
    padding: 10px 20px;
  }
}
@media (max-height: 450px) and (min-width: 700px) {
  .banner .gridBanner .leftBlock {
    width: 60%;
  }
  .banner .gridBanner .rightBlock {
    width: 40%;
  }
  .banner .container {
    margin: 0px;
    padding: 10px;
  }
  /*.bannerText {
    margin-top: 20px;
  } */
  h1 {
    margin-bottom: 10px;
  }
  .bannerInner .banner-btn {
    margin-top: 10px;
  }
  .bannerLogo img {
    height: 60px;
  }
}
@media (max-height: 450px) {
  .bannerText {
    margin-top: 20px;
  }
  h1 {
    margin-bottom: 10px;
    font-size: 1.9rem;
  }
  .bannerInner .banner-btn {
    margin-top: 10px;
  }
  .banner-descr {
    color: white;
  }
}

@media (max-width: 450px) {
  .banner h1 {
    font-size: 1.8rem;
  }

  .banner h2 {
    font-size: 1.5rem;
  }

  .banner h3 {
    font-size: 1.2rem;
  }
  .bannerLogo img {
    height: 60px;
  }
  .banner .container {
    padding: 0px;
  }
}
