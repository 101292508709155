.tesimonials__external {
  position: relative;
  background: url("../images/fourthBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.tesimonials__external h3,
.tesimonials__external .descr {
  color: #fff;
}
.carousel-control-prev {
  left: -60px;
}

.carousel-control-next {
  right: -60px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: var(--second-accent-color);
  padding: 15px;
  display: block;
  border-radius: 50%;
  background-size: 24px;
  background-position-x: 8px;
}
.tesimonials__external h5 {
  color: var(--contrast-color);
}
.testimonial-content p {
  border: 2px solid var(--main-color);
  border-radius: 10px;
  position: relative;
  left: -20px;
  bottom: -10px;
  background: #fff;
  z-index: 20;
}

.testimonial-content p:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var(--main-color);
  transform: rotate(45deg);
  left: -11px;
  bottom: 13px;
  background: #fff;
  border-right: none;
  border-top: none;
}
#testimonials .carousel.slide {
  width: 100%;
  padding: 35px;
}

#testimonials .carousel.slide p {
  font-style: italic;
}

#testimonials .user-avatar img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 20;
}
#testimonials .user-avatar h5 {
  z-index: 20;
}

.light2 {
  position: absolute;
  width: 0px;
  /* height: 100px; */
  opacity: 0.95;
  background-color: var(--contrast-color);
  box-shadow: #fff 0px 0px 20px 2px;
  opacity: 0;
  top: 0vh;
  /* top: 50vh;*/
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  z-index: 1;
}

@media screen and (max-width: 1200px) {
  .slide-inner .user-avatar {
    display: flex;
    flex-flow: column;
    place-content: flex-end;
    justify-content: center;
    align-items: center;
  }
  .testimonial-content p:after {
    bottom: 50%;
  }
}
@media screen and (max-width: 992px) {
  .testimonial-content p:after {
    display: none;
  }
  #testimonials .carousel-control-prev {
    left: -10px;
  }

  #testimonials .carousel-control-next {
    right: -10px;
  }

  #testimonials .user-avatar img {
    width: 100px;
  }
  .testimonial-content p {
    left: 0;
  }
  #testimonials .carousel-control-prev,
  #testimonials .carousel-control-next {
    top: -200px;
  }
}
@media (max-width: 767px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}
@media screen and (max-height: 450px) {
  #testimonials .user-avatar img {
    width: 150px;
  }
  .testimonial-content p {
    left: 0;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-size: 18px;
    background-position-x: 6px;
  }
}

@media screen and (max-width: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}
@media screen and (max-height: 450px) {
  .tesimonials__external .container {
    padding-top: 40px;
  }
}
