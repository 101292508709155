.howToStart {
  position: relative;
}
.howToStart .container.move {
  margin-left: 35vw;
}
.howToStart .container.unmove {
  margin-left: 0 auto;
}

.howToStart h2 {
  color: var(--main-color);
}
.howToStart .list {
  list-style: none;
  padding: 0;
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  justify-content: space-around;
  column-gap: 20px;
}

.howToStart .list li {
  background: var(--contrast-color);
  padding: 5px;
  border-radius: 5px;
  border: 2px solid #f2c46d;
  box-shadow: 2px -2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px -2px 5px 0 rgba(0, 0, 0, 0.1), 2px 2px 5px 0 rgba(0, 0, 0, 0.1),
    -2px 2px 5px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s all linear;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.howToStart .list li .firstL {
  border-radius: 5px;
  padding: 5px;
  border: 2px dotted #9d84bf;
  height: 100%;
  width: 100%;
}

.howToStart .list li .secondL {
  border-radius: 5px;
  padding: 10px;
  border: 2px solid #f2c46d;
  height: 100%;
  width: 100%;
}
.howToStart .btn-primary {
  position: relative;
}
.list li .number {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
  top: -35px;
  left: calc(50% - 15px);
}

@media (max-width: 700px) {
  .howToStart .list {
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: space-around;
  }
  .howToStart .list li {
    width: 48%;
  }
}
@media (max-width: 450px) {
  .howToStart .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 20px 0;
  }
}

@media (max-height: 450px) {
  .howToStart .container {
    padding-bottom: 50px;
  }
  .howToStart .list li {
    width: 100%;
  }
  .howToStart .list {
    margin: 0;
  }
}
@media (max-height: 450px) and (min-width: 700px) {
  .howToStart .container {
    padding: 20px 10px;
  }
  .howToStart .list {
    row-gap: 20px;
    column-gap: 20px;
  }
}
